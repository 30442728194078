<template>
    <div class="flex items-center lg:justify-end gap-6 text-blacks-100">
        <div @click="showSidebar = !showSidebar" class="hidden lg:flex items-center gap-2 cursor-pointer pb-6">
            <p v-if="showSidebar">Ẩn bộ lọc</p>
            <p v-else>Mở bộ lọc</p>
            <IconsShowFilter class="w-6 h-6"></IconsShowFilter>
        </div>
        <div
            @click="showSortFeatures = !showSortFeatures"
            class="relative flex items-center gap-2 cursor-pointer text-blacks-100 group pt-5 lg:pt-0 pb-3 lg:pb-6 z-100 min-w-61"
        >
            <p class="text-sm lg:text-base">Xếp theo: {{ currentTitle }}</p>
            <i class="i-ic:outline-keyboard-arrow-down w-6 h-6 group-hover:rotate-180 duration-200"></i>
            <ul
                class="absolute top-full bg-white shadow-lg inset-0 mt-5 h-fit opacity-0 transform translate-y-1 group-hover:opacity-100 group-hover:-translate-y-2 duration-200 b-0.5 invisible group-hover:visible"
            >
                <li
                    @click="$emit('update:currentSort', item), (currentTitle = item.title)"
                    v-for="(item, index) in listSort"
                    :key="index"
                    :class="currentSort.key == item.key ? 'bg-blacks-100 text-white' : ''"
                    class="px-4 py-2 hover:bg-blacks-100 hover:text-white text-sm lg:text-base"
                >
                    {{ item.title }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
const showSidebar = defineModel()
const showSortFeatures = ref(false)
const props = defineProps({
    currentSort: {
        type: Object as any
    }
})
defineEmits(['update:currentSort'])
const currentTitle = ref()
const listSort = [
    { title: 'Sản phẩm nổi bật', key: 'featured', value: true },
    { title: 'Mới nhất', key: 'newest', value: true },
    { title: 'Cũ nhất ', key: 'oldest', value: true },
    { title: 'Giá cao - Giá thấp ', key: 'descending_price', value: true },
    { title: 'Giá thấp - Giá cao ', key: 'ascending_price', value: true },
    { title: 'A-Z', key: 'az', value: true },
    { title: 'Z-A', key: 'za', value: true }
]
onMounted(() => {
    listSort.forEach((item: any) => {
        if (item.key == props.currentSort.key) {
            currentTitle.value = item.title
        }
    })
})
</script>

<style></style>
