<template>
    <div class="pb-10 flex-none">
        <!-- Categories -->
        <!-- <pre>{{ urlModel }}</pre> -->
        <SubSideBar
            v-if="filterCP?.categories"
            v-model:showSidebar="showSidebar"
            v-model="valueOfCategories"
            :data="filterCP?.categories"
            heading="Loại sản phẩm"
        />
        <div v-if="filterCP?.categories" class="w-full h-1px bg-blacks-10 my-4"></div>
        <!-- Khoảng giá -->
        <SubSideBar
            v-if="filterCP?.features"
            v-model:showSidebar="showSidebar"
            v-model="valueOfFeatures"
            :data="filterCP?.features"
            heading="Tính năng"
        />
        <div class="w-full h-1px bg-blacks-10 my-4"></div>
        <!--  -->

        <SubSideBar
            v-if="filterCP?.colors"
            v-model:showSidebar="showSidebar"
            v-model="valueOfColors"
            :data="filterCP?.colors"
            heading="Màu sắc"
        />
        <div class="w-full h-1px bg-blacks-10 my-4"></div>
        <button @click="handleCopyLink()" class="flex items-center gap-2 mt-12 cursor-pointer">
            <i class="i-ic:round-share w-4.5 h-4.5 text-blacks-70"></i>
            <p class="text-blacks-50 font-medium">Chia sẻ</p>
        </button>
        <Teleport v-if="true" to="body">
            <GlobalPopupCopySuccess v-model:isOpen="copyLink" />
        </Teleport>
    </div>
</template>

<script setup lang="ts">
import SubSideBar from './SubSideBar'
const showSidebar = defineModel()
const props = defineProps({
    filter: {
        type: Object as any
    },
    urlModel: {
        type: String as any
    }
})

const copyToClipboard = (url: any) => {
    var sampleTextarea = document.createElement('textarea')
    document.body.appendChild(sampleTextarea)
    sampleTextarea.value = url //save main text in it
    sampleTextarea.select() //select textarea contenrs
    document.execCommand('copy')
    document.body.removeChild(sampleTextarea)
}

const href = ref()
const copyLink = ref(false)
const handleCopyLink = () => {
    copyLink.value = true
    copyToClipboard(href.value)
    setTimeout(() => {
        copyLink.value = false
    }, 5000)
}
const route = useRoute()

function parseCategoryString(categoryString: any) {
    return categoryString ? categoryString.split(',').map((item: any) => parseInt(item.trim())) : []
}
const filterCP = computed(() => props.filter)

const valueOfCategories = ref(
    filterCP.value?.categories && filterCP.value?.categories.length > 0
        ? filterCP.value?.categories
              .filter((item: any) => {
                  if (!parseCategoryString(route.query?.categories)) return
                  return parseCategoryString(route.query?.categories).length > 0
                      ? parseCategoryString(route.query?.categories).find((itemChild: any) => itemChild == item.id)
                      : ''
              })
              .map((item: any) => item.id)
        : []
)
const valueOfFeatures = ref(
    filterCP.value?.features && filterCP.value?.features.length > 0
        ? filterCP.value?.features
              .filter((item: any) => {
                  if (!parseCategoryString(route.query?.features)) return
                  return parseCategoryString(route.query?.features).length > 0
                      ? parseCategoryString(route.query?.features).find((itemChild: any) => itemChild == item.id)
                      : ''
              })
              .map((item: any) => item.id)
        : []
)
const valueOfPrices = ref(
    filterCP.value?.price_ranges && filterCP.value?.price_ranges.length > 0
        ? filterCP.value?.price_ranges
              .filter((item: any) => {
                  if (!parseCategoryString(route.query?.price_range)) return
                  return parseCategoryString(route.query?.price_range).length > 0
                      ? parseCategoryString(route.query?.price_range).find((itemChild: any) => itemChild == item.id)
                      : ''
              })
              .map((item: any) => item.id)
        : []
)
const valueOfColors = ref(
    filterCP.value?.colors && filterCP.value?.colors.length > 0
        ? filterCP.value?.colors
              .filter((item: any) => {
                  if (!parseCategoryString(route.query?.colors)) return
                  return parseCategoryString(route.query?.colors).length > 0
                      ? parseCategoryString(route.query?.colors).find((itemChild: any) => itemChild == item.id)
                      : ''
              })
              .map((item: any) => item.id)
        : []
)
const queryCategories = ref()
const queryPrices = ref()
const queryColors = ref()
const queryFeatures = ref()
const emits = defineEmits(['dataSearch'])
watch(
    () => [valueOfCategories.value, valueOfPrices.value, valueOfFeatures.value, valueOfColors.value],
    ([newCategories, newPrices, newFeatures, newColors]) => {
        queryCategories.value =
            newCategories && newCategories.length > 0 ? newCategories.map((id: any) => id).join(',') : ''
        queryPrices.value = newPrices && newPrices.length > 0 ? newPrices.map((id: any) => id).join(',') : ''
        queryColors.value = newColors && newColors.length > 0 ? newColors.map((id: any) => id).join(',') : ''
        queryFeatures.value = newFeatures && newFeatures.length > 0 ? newFeatures.map((id: any) => id).join(',') : ''

        useRouter().push({
            path: route.path,
            query: {
                categories: queryCategories.value,
                price_range: queryPrices.value,
                colors: queryColors.value,
                features: queryFeatures.value,
                sort: route?.query?.sort,
                brand: route?.query?.brand ? route?.query?.brand.trim() : ''
            }
        })

        emits('dataSearch', {
            categories: newCategories.map((id: any) => id),
            price_range: newPrices.map((id: any) => id),
            colors: newColors.map((id: any) => id),
            features: newFeatures.map((id: any) => id)
        })
    }
)
const isLoaded = ref(false)
onMounted(() => {
    const rootURL = window.location.origin
    href.value = rootURL + props.urlModel
    isLoaded.value = true
})
</script>
<style scoped>
input[type='checkbox'].select-category {
    @apply rounded-5px;
}

.checkbox-custom input[type='checkbox'].select-category:checked {
    @apply bg-blacks-100 duration-200;
}
.checkbox-custom input[type='checkbox']:before,
.checkbox-custom input[type='checkbox']:after {
    @apply bg-white;
}
</style>
