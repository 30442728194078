<template>
    <div v-auto-animate>
        <div @click="isShow = !isShow" class="flex items-center justify-between cursor-pointer">
            <p :class="showSidebar ? 'text-blacks-100' : 'text-white'">{{ heading }}</p>
            <div :class="showSidebar ? 'text-blacks-100 flex items-center ' : 'text-white'">
                <i v-if="isShow" class="i-ic:baseline-minus w-5 h-7.5"></i>
                <i v-else class="i-ic:baseline-add w-5 h-7.5"></i>
            </div>
        </div>

        <div v-if="isShow" class="space-y-4 xl:space-y-6 mt-3">
            <label v-for="item in data" class="flex items-start gap-3 checkbox-custom cursor-pointer">
                <input
                    type="checkbox"
                    name=""
                    :value="item.id"
                    v-model="values"
                    :class="showSidebar ? '!b-blacks-70' : '!b-white'"
                    class="select-category flex-none cursor-pointer"
                />
                <p :class="showSidebar ? 'text-blacks-70' : 'text-white'" class="text-sm lg:text-base">
                    {{ item.title }}
                </p>
            </label>
        </div>
    </div>
</template>

<script setup lang="ts">
defineProps({
    heading: {
        type: String
    },
    data: {
        type: Object as any
    },
    showSidebar: {
        type: Boolean
    }
})
const values = defineModel()

const isShow = ref(true)
</script>

<style scoped>
input[type='checkbox'].select-category {
    @apply rounded-5px;
}

.checkbox-custom input[type='checkbox'].select-category:checked {
    @apply bg-blacks-100 duration-200;
}
.checkbox-custom input[type='checkbox']:before,
.checkbox-custom input[type='checkbox']:after {
    @apply bg-white;
}
</style>
