<template>
    <div class="">
        <div
            @click="showSidebar = true"
            class="flex items-center gap-2 px-3 py-1 bg-blacks-100 text-white cursor-pointer hover:bg-blacks-100/80 duration-200 w-max"
        >
            <p>Filters</p>
            <i class="i-ic:baseline-filter-alt text-xl" />
        </div>
        <div
            :class="showSidebar ? 'fixed top-0 left-0 ' : 'fixed top-0 -left-full'"
            class="w-full h-full duration-400 z-1000 flex overflow-x-hidden"
        >
            <div class="bg-white w-10/12 py-5 h-full">
                <div class="flex items-center justify-between text-blacks-100 text-xl shadow-md px-5 pb-5">
                    <p class="">Filters</p>
                    <i @click="showSidebar = false" class="i-ic:sharp-close cursor-pointer" />
                </div>
                <SideBar
                    v-model="showSidebar"
                    :filter="filter"
                    @dataSearch="changeData"
                    class="text-blacks-100 p-5 h-[calc(100vh-68px)] overflow-auto custom-scrollbar"
                />
            </div>
            <div
                @click="showSidebar = false"
                :class="showSidebar ? 'translate-x-0 delay-150' : 'translate-x-200'"
                class="bg-black/50 w-2/12 h-full transform duration-300"
            ></div>
        </div>
    </div>
</template>

<script setup lang="ts">
import SideBar from './Sidebar'
defineProps({
    filter: {
        type: Object as any
    }
})
const showSidebar = ref(false)
const emits = defineEmits(['dataSearch'])
const changeData = async (data: any) => {
    emits('dataSearch', data)
}
watch(
    () => showSidebar.value,
    () => {
        if (showSidebar.value == true) {
            document.querySelector('body').classList.add('overflow-hidden')
        } else {
            document.querySelector('body').classList.remove('overflow-hidden')
        }
    }
)
</script>

<style></style>
