<template>
    <section ref="wrapperFilter">
        <div class="pt-12 mr-[5%] hidden lg:block lg:sticky top-15 bg-white z-100">
            <SortProduct v-model="showSidebar" v-model:currentSort="currentSort" />
        </div>
        <div :class="showSidebar ? 'active' : ''" class="relative wrapper-filter-layout">
            <div class="sidebar">
                <SideBar
                    v-model="showSidebar"
                    :filter="filter"
                    :urlModel="urlModel"
                    :class="
                        showSidebar
                            ? 'translate-x-0 visible opacity-100 pr-5 overflow-auto custom-scrollbar duration-200 pl-10'
                            : '-translate-x-full invisible opacity-0 duration-400 '
                    "
                    @dataSearch="changeData"
                    class="sticky h-[calc(100vh-160px)] top-40 hidden lg:block transform bg-white transform mb-24 max-w-100 w-full"
                />
            </div>
            <div class="sticky top-18 lg:top-22 mr-auto py-6 lg:hidden bg-white z-1000 w-full shadow-sm px-5">
                <SidebarMobile :filter="filter" @dataSearch="changeData" />
                <SortProduct v-model="showSidebar" v-model:currentSort="currentSort" />
            </div>

            <div
                :class="
                    (showSidebar && keyFilter === 'categories' && dataRender?.products.length > 0) ||
                    (showSidebar && keyFilter === 'collection' && dataRender?.products.length > 0)
                        ? ''
                        : ''
                "
                class="relative duration-300 layout-list-item"
            >
                <div
                    v-if="dataRender?.products && dataRender?.products.length > 0"
                    class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mx-5 lg:mx-0 pb-24"
                >
                    <WrapperProduct v-for="(item, index) in dataRender.products" :key="item.id" :item="item" />
                </div>
                <div v-else class="container">
                    <p class="text-gray-500 lg:text-xl py-10">Thông tin sản phẩm đang được cập nhật !!</p>
                </div>
                <div
                    v-if="checkFilterCount && checkFilterCount > 0 && checkFilterCount > 9 && checkFilterCount > LIMIT"
                    @click="showMoreProducts"
                    class="btn-effect-light md:w-full b-1 b-blacks-90 text-lg font-medium py-3 xl:py-5 text-center mt-12 cursor-pointer mx-5 lg:mx-0"
                >
                    Xem thêm
                </div>
                <GlobalLoading v-if="loadingFilter" />
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import SideBar from './components/Sidebar.vue'
import SidebarMobile from './components/SidebarMobile.vue'
import SortProduct from './components/SortProduct.vue'
import WrapperProduct from './components/WrapperProduct.vue'
const wrapperFilter = ref<HTMLElement | any>(null)
const props = defineProps({
    filter: {
        type: Object as any
    },
    keyFilter: {
        type: String
    },
    urlModel: {
        type: String as any
    }
})
const slug = useRoute().params.slug
const dynamicKey: any = props.keyFilter
const dynamicValue = { slug: slug }
const showSidebar = ref(true)
const route = useRoute()
const LIMIT = ref(9)
const currentSort = ref(route.query.sort ? { key: route.query.sort, value: true } : { key: 'featured', value: true })
const dataSort = computed(() => {
    return Object.keys(currentSort.value).reduce((result: any, key) => {
        result[currentSort.value.key] = currentSort.value.value
        return result
    }, {})
})
watch(
    () => currentSort.value,
    (newValue) => {
        useRouter().push({
            path: route.path,
            query: {
                categories: route.query?.categories,
                price_range: route.query?.price_range,
                // colors: route.colors,
                features: route.query?.features,
                sort: currentSort.value?.key
            }
        })
        const convertData = Object.keys(route.query).reduce((result: any, key) => {
            let value
            if (key == 'brand') {
                value = route.query[key]
            } else {
                value = route.query[key].split(',')
            }
            result[key] = value
            return result
        }, {})
        fetchProds(convertData)
    }
)
const infoFilter = ref({})
const globalSettings = inject('globalSettings')
const dataRender = ref()

const checkFilterCount = ref(0)
const loadingFilter = ref(false)
const { searchProducts } = useProducts(globalSettings)
const fetchProds = async (query: any) => {
    loadingFilter.value = true
    wrapperFilter.value.scrollIntoView({ behavior: 'smooth' })
    const res: any = await searchProducts(
        { [dynamicKey]: dynamicValue },
        {
            categories: query?.categories,
            // price_range: query?.price_range,
            brand: query?.brand,

            colors: query?.colors,
            features: query?.features,
            sort: dataSort.value
        }
    ).catch((err) => console.log(err))
    infoFilter.value = {
        categories: query?.categories,
        brand: query?.brand,
        // price_range: query?.price_range,
        features: query?.features,
        colors: query?.colors,
        sort: dataSort.value
    }
    dataRender.value = res
    checkFilterCount.value = res.filter_count

    loadingFilter.value = false
}
const changeData = async (data: any) => {
    infoFilter.value = {
        categories: data?.categories,
        // price_range: data?.price_range,
        features: data?.features,
        colors: data?.colors,
        sort: dataSort.value,
        brand: route.query.brand
    }
    await fetchProds(infoFilter.value)
}
const showMoreProducts = async () => {
    loadingFilter.value = true

    const data: any = await searchProducts({ [dynamicKey]: dynamicValue }, infoFilter.value, {
        limit: (LIMIT.value += 9)
    }).catch((err) => console.log(err))

    dataRender.value = data
    loadingFilter.value = false
}
onMounted(() => {
    const convertData = Object.keys(route.query).reduce((result: any, key) => {
        let value
        if (key == 'brand') {
            value = route.query[key]
        } else {
            value = route.query[key].split(',')
        }
        result[key] = value
        return result
    }, {})
    fetchProds(convertData)
})
</script>

<style>
@media (min-width: 1024px) {
    .containerProductFilter {
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
    }
    .wrapper-filter-layout {
        display: grid;
        grid-template-columns: 1fr 1000px 1fr;
        grid-gap: 0px;
    }
    .wrapper-filter-layout.active {
        display: grid;
        grid-template-columns: 1fr 600px 1fr;
        grid-gap: 54px;
    }
}
@media (min-width: 1312px) {
    .containerProductFilter {
        max-width: 1246px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0.9375rem;
        padding-right: 0.9375rem;
    }
    .wrapper-filter-layout {
        display: grid;
        grid-template-columns: 1fr 1246px 1fr;
        grid-gap: 0px;
    }
    .wrapper-filter-layout.active {
        display: grid;
        grid-template-columns: 1fr 700px 1fr;
        grid-gap: 54px;
    }
}

@media (min-width: 1900px) {
    .wrapper-filter-layout.active {
        display: grid;
        grid-template-columns: 1fr 1246px 1fr;
        grid-gap: 54px;
    }
}
.sidebar {
    @apply flex justify-end;
}
.layout-list-item {
    grid-column: 2/4;
    padding-right: 5%;
}
</style>
